import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["submenu"]

  connect() {
    // console.log("Manage sideMenu controller connected.", this.element)
  }

  toggle() {
    console.log('toggle manage side menu item')
    this.submenuTarget.classList.toggle('hidden');
  }

}