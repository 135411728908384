console.log("loading scroll animation js...")
const callback = function (entries) {
  entries.forEach((entry) => {
    // console.log(entry);

    if (entry.isIntersecting) {
      entry.target.classList.add("motion-safe:animate-fadeInUp");
    } else {
      entry.target.classList.remove("motion-safe:animate-fadeInUp");
    }
  });
};

document.addEventListener('turbo:load', () => {

  const observer = new IntersectionObserver(callback);  
  const targets = document.querySelectorAll(".reveal-on-scroll");
  
  targets.forEach(function (target) {
    target.classList.add("opacity-0");
    observer.observe(target);
  });

});
