import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="store--order"
export default class extends Controller {
  static targets = ["lineItems", "orderTotal"]

  connect() {
    console.log("store/order controller connected.", this.element)
    // Listen for the custom "line-item-updated" event on all line item elements
    const lineItemElements = this.lineItemsTarget.querySelectorAll("[data-controller='store--item']")
    lineItemElements.forEach((lineItem) => {
      lineItem.addEventListener("line-item-updated", this.recalculateOrderTotal.bind(this))
    })

    // Calculate the initial order total
    this.recalculateOrderTotal()
  }

  disconnect() {
    // Remove event listeners when the controller is disconnected
    const lineItemElements = this.lineItemsTarget.querySelectorAll("[data-controller='store--item']")
    lineItemElements.forEach((lineItem) => {
      lineItem.removeEventListener("line-item-updated", this.recalculateOrderTotal.bind(this))
    })
  }

  recalculateOrderTotal() {
    let orderTotal = 0
    const lineItemElements = this.lineItemsTarget.querySelectorAll("[data-controller='store--item']")
    lineItemElements.forEach((lineItem) => {
      const subtotal = parseFloat(lineItem.querySelector("[data-store--item-target='subtotal']").value)
      orderTotal += subtotal
    })
    this.orderTotalTarget.textContent = this.centsToDollars(orderTotal)

  }

  // Function to convert cents to dollars
  centsToDollars(cents) {
    const dollars = (cents / 100).toFixed(2);
    return '$' + dollars;
  }

}
