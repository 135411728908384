import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sidebar"]
  connect() {
    console.log("Sidebar controller connected.", this.element)
  }

  toggleMenu() {
    console.log('toggle sidebar')
    this.sidebarTarget.classList.toggle('hidden');
  }
}
