import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="numbers"
export default class extends Controller {
  static targets = ["count"]
  connect() {
    console.log("connecting to numbers", this.element)
  }
  decrement() {
    console.log('decrement count')
    this.countTarget.stepDown();
    this.countTarget.dispatchEvent(new Event("change"));
  }
  increment() {
    console.log('increment count')
    this.countTarget.stepUp();
    this.countTarget.dispatchEvent(new Event("change"));
  }

}
