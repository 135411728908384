import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="prices"
export default class extends Controller {
  static targets = ["checkbox", "options"]
  connect() {
    // console.log("connecting to prices", this.element)
    // console.log('optionsTarget', this.optionsTarget)
  }

  check(event) {
    // console.log(this.checkboxTarget.checked)
    // console.log(event.target.checked)
    // console.log(event.target.id)
    // console.log(event.target.value)
    this.request = new Request(event.target.dataset.pricesUrl);
    this.fetchPrices(this.request);
  }

  fetchPrices(request) {
    fetch(request)
      .then((response) => {
        if (response.status == 200) {
          response.text().then((text) => this.optionsTarget.innerHTML = text);
        } else {
          console.log("Couldn't load data");
        }
      })
  }



}
