import { Controller } from "@hotwired/stimulus"
import Cropper from "cropperjs"

// Connects to data-controller="cropper"
export default class extends Controller {
  static targets = ["image", "crop_x", "crop_y", "crop_w", "crop_h"]
  static values = { 
    widthRatio: { type: Number, default: 1}, 
    heightRatio: { type: Number, default: 1} 
  }

  connect() {
    console.log("Cropper controller connected.", this.element)
    let _this = this
    this.cropper = new Cropper(this.imageTarget, {
      zoomable: false,
      initialAspectRatio: this.widthRatioValue / this.heightRatioValue,
      aspectRatio: this.widthRatioValue / this.heightRatioValue,
      ready(event) { _this.logReadyEvent(event) },
      crop(event) {_this.updateCoordinates(event)}
    })    
  }

  logReadyEvent(event) {
    console.log(event);
    console.log('getImageData', this.cropper.getImageData());
    console.log('getCanvasData', this.cropper.getCanvasData());
  }

  updateCoordinates(event) {
    // this.logCropEvent(event)
    this.crop_xTarget.value = event.detail.x
    this.crop_yTarget.value = event.detail.y
    this.crop_wTarget.value = event.detail.width
    this.crop_hTarget.value = event.detail.height
  }

  changeAspectRatio(event) {
    console.log('changeAspectRatio', event);
    var aspectRatioString = event.currentTarget.dataset.aspect
    // do not use direct eval when using a bundler like esbuild
    var aspectRatioNumeric = Function(`return ${aspectRatioString}`)();
    // console.log('aspectRatioString', aspectRatioString);
    // console.log('aspectRatioNumeric', aspectRatioNumeric);
    this.cropper.setAspectRatio(aspectRatioNumeric);
  }

  logCropEvent(event) {
    console.log(event.detail.x);
    console.log(event.detail.y);
    console.log(event.detail.width);
    console.log(event.detail.height);
    console.log(event.detail.rotate);
    console.log(event.detail.scaleX);
    console.log(event.detail.scaleY);
  }

}
