import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="store--item"
export default class extends Controller {
  static targets = ["quantity", "amount", "subtotal", "subtotalDisplay"]

  connect() {
    console.log("store/item controller connected.", this.element)
    this.quantityTarget.addEventListener("change", this.recalculateSubtotal.bind(this))
  }

  disconnect() {
    // Remove the event listener when the controller is disconnected
    this.quantityTarget.removeEventListener("change", this.recalculateSubtotal.bind(this))
  }

  recalculateSubtotal() {
    const quantity = parseInt(this.quantityTarget.value)
    const unitPrice = parseInt(this.amountTarget.value)
    const subtotal = quantity * unitPrice
    this.subtotalTarget.value = subtotal
    this.subtotalDisplayTarget.textContent = this.centsToDollars(subtotal)
    // Trigger an event to notify the parent (Order) controller
    this.element.dispatchEvent(new Event("line-item-updated"))
  }

  // Function to convert cents to dollars
  centsToDollars(cents) {
    const dollars = (cents / 100).toFixed(2);
    return '$' + dollars;
  }

}
