import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ['container']
  connect() {
    console.log("connecting to modal", this.element)
  }
  close() {
    this.element.classList.add('hidden');
    this.element.remove();
  }
  closeWithKeyboard(e) {
    if (e.keyCode === 27 && !this.element.classList.contains('hidden')) {
      this.close();
      this.element.remove();
    }
  }

}
