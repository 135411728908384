import { Controller } from "@hotwired/stimulus"

import videojs from 'video.js'
// import 'video.js/dist/video-js.css'

export default class extends Controller {
  static targets = [ "player" ]

  connect() {
    console.log("Video controller connected.", this.element)
  }
  initialize() {
    let videoPlayer = videojs(this.playerTarget, {
      controls: true,
      playbackRates: [0.5, 1.0, 2.0],
      autoplay: false,
      fluid: true
    })
    videoPlayer.addClass('video-js')
    videoPlayer.addClass('vjs-big-play-centered')
  }
  disconnect() {}

}
