import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import { put } from "@rails/request.js"

export default class extends Controller {
  static values = { url: String }

  connect() {
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      group: 'nested',
      onEnd: this.updatePosition.bind(this)
    })
  }
  async updatePosition(event) {
    // console.log(event.from)
    // console.log(event.to)
    const response = await put(`${this.urlValue}`, {
      body: JSON.stringify({
        old_parent_sgid: event.from.dataset.sgid,
        new_parent_sgid: event.to.dataset.sgid,
        sgid: event.item.dataset.sgid,
        position: event.newIndex + 1
      })
    })
    if (response.ok) {
      console.log(event.item.dataset.sgid)
    }
  }

}
