import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["mobilemenu"]

  connect() {
    console.log("Mobile menu controller connected.", this.element)
    console.log('target', this.mobilemenuTarget)
  }

  toggleMenu() {
    console.log('toggle mobile menu')
    this.mobilemenuTarget.classList.toggle('hidden');
  }
}
