// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "@rails/actiontext"

import "./scroll_animation"
import "chartkick/chart.js"

import LocalTime from "local-time"
LocalTime.start()
