import { Controller } from "@hotwired/stimulus"

// solution for integrating tinyMCE with stimulus from here:
// https://dev.to/djchadderton/making-tinymce-work-with-rails-turbolinks-and-stimulus-2ooa

// the fix for the deprecated root_block false from here:
// https://github.com/tinymce/tinymce/discussions/7342

// Import TinyMCE
import tinymce from "tinymce/tinymce"

// fails to load dom/model.js otherwise
import "tinymce/models/dom"

// Import icons
import 'tinymce/icons/default/icons'

// Import theme
import 'tinymce/themes/silver/theme';

// skin is imported through manage.css in asset/stylesheets
// Import skin
// import 'tinymce/skins/ui/oxide/skin.min.css';

// Import plugins

// import 'tinymce/plugins/advlist';
// import 'tinymce/plugins/anchor';
// import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
// import 'tinymce/plugins/autosave';
// import 'tinymce/plugins/bbcode';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
// import 'tinymce/plugins/codesample';
// import 'tinymce/plugins/colorpicker';
// import 'tinymce/plugins/contextmenu';
// import 'tinymce/plugins/directionality';
// import 'tinymce/plugins/emoticons';
// import 'tinymce/plugins/fullpage';
// import 'tinymce/plugins/fullscreen';
// import 'tinymce/plugins/help';
// import 'tinymce/plugins/hr';
// import 'tinymce/plugins/image';
// import 'tinymce/plugins/imagetools';
// import 'tinymce/plugins/insertdatetime';
// import 'tinymce/plugins/legacyoutput';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
// import 'tinymce/plugins/media';
// import 'tinymce/plugins/nonbreaking';
// import 'tinymce/plugins/noneditable';
// import 'tinymce/plugins/pagebreak';
// import 'tinymce/plugins/paste';
// import 'tinymce/plugins/preview';
// import 'tinymce/plugins/print';
// import 'tinymce/plugins/quickbars';
// import 'tinymce/plugins/save';
// import 'tinymce/plugins/searchreplace';
// import 'tinymce/plugins/spellchecker';
// import 'tinymce/plugins/tabfocus';
import 'tinymce/plugins/table';
// import 'tinymce/plugins/template';
// import 'tinymce/plugins/textcolor';
// import 'tinymce/plugins/textpattern';
// import 'tinymce/plugins/toc';
// import 'tinymce/plugins/visualblocks';
// import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';


export default class extends Controller {
  static targets = ['input']

  connect() {
    let config = Object.assign({ target: this.inputTarget }, this.defaults)
    tinymce.init(config)
  }

  disconnect() {
    tinymce.remove()
    // this does not disconnect the editor instance
    // if (!this.preview) {
    //   console.log('disconnecting tinymce editor instance')
    //   tinymce.remove()
    // }
  }

  // get preview() {
  //   return (
  //     document.documentElement.hasAttribute('data-turbolinks-preview') ||
  //     document.documentElement.hasAttribute('data-turbo-preview')
  //   )
  // }

  initialize() {
    let _this = this;
    this.defaults = {
      setup: function (editor) {
        editor.on("PostProcess", function (ed) {
          ed.content = _this.removeOuterParagraphTags(ed.content);
          ed.content = _this.removeOuterNonBreakingSpaces(ed.content);
        })
      },
      promotion: false,
      content_css: false,
      skin: false,
      // forced_root_block: "p",
      paste_as_text: true,
      menubar: true,
      inline: true,
      toolbar: [
        'save | undo redo | bold italic underline strikethrough superscript | formatselect blockquote numlist bullist | alignleft aligncenter alignright | link hr charmap code | table'
      ],
      mobile: {
        toolbar: [
          'save | undo redo | styles | bold italic underline strikethrough superscript | formatselect blockquote numlist bullist | alignleft aligncenter alignright | link hr charmap code | table'
        ]
      },
      plugins: 'link lists charmap table code autoresize wordcount',
      style_formats: [
        // { title: 'Heading 1', format: 'h1' },
        // { title: 'Heading 2', format: 'h2' },
        { title: 'Heading 3', format: 'h3' },
        { title: 'Heading 4', format: 'h4' },
        { title: 'Heading 5', format: 'h5' },
        { title: 'Heading 6', format: 'h6' },
        { title: 'Paragraph', format: 'p' }
      ],
      max_height: 700,
      default_link_target: '_blank',
      link_title: false,
      autoresize_bottom_margin: 10,
      link_context_toolbar: true,
      relative_urls: false,
      browser_spellcheck: true,
      element_format: 'html',
      // invalid_elements: ['span'],
      // content_style: 'html { font-family: Roboto, sans-serif; line-height: 1.5; }'
    }
  }

  removeOuterParagraphTags(html) {
    if (html.startsWith("<p>")) {
      var count = (html.match(/<p>/g) || []).length;
      // console.log(count);
      // if the only p tag is the root block, remove it
      if (count == 1) {
        // Remove the first p tag at the beginning of the string.
        html = html.replace(/<p>/, "");
        // And remove the closing p tag at the end of the string.
        html = html.replace(/<\/p>$/, "");
      }
    }
    return html;
  }

  removeOuterNonBreakingSpaces(html) {
    if (html.startsWith("&nbsp;")) {
      html = html.replace(/&nbsp;/, "");
        html = html.replace(/<\/p>$/, "");
    }
    if (html.endsWith("&nbsp;")) {
      html = html.replace(/&nbsp;$/, "");
    }
    return html;
  }

}
